import React, { useState, useEffect, useCallback } from "react";
import { Pagination, Row, Col, Collapse, Button, Modal, message } from "antd";
import { CaretRightOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

import PageHeader from "../Layout/PageHeader";
import PickupRequestFilters from "./PickupRequestFilters";
import PickupRequestList from "./PickupRequestList";

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext";

const today = moment();
const threeMonthsAgo = moment().subtract(3, "months");

const PickupRequest = () => {
  const { permissions } = usePermissions();

  const [loading, setLoading] = useState({ orders: false, export: false });
  const [pickupRequest, setPickupRequest] = useState([]);
  const [filters, setFilters] = useState({
    startDate: threeMonthsAgo,
    endDate: today,
  });
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });

  const fetchPickupRequests = useCallback(async () => {
    setLoading((prevLoading) => ({ ...prevLoading, orders: true }));

    const params = {
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.serviceProvider && {
        serviceProvider: filters.serviceProvider,
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };
    const res = await axios.get("/api/riderInfos", { params });
    setPickupRequest(res.data.data);
    setPagination(res.data.pagination);
    setLoading((prevLoading) => ({ ...prevLoading, orders: false }));
  }, [pagination.currentPage, pagination.limit, filters]);

  const exportCSV = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, export: true }));

    const params = {
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.serviceProvider && {
        serviceProvider: filters.serviceProvider,
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const messageContent = (
      <p>
        Are you sure you want to download {pagination.totalSize} pickup request
        data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Pickup Request List ? `,
      async onOk() {
        await axios
          .get("/api/riderInfo/csv", { params })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });

    setLoading((prevLoading) => ({ ...prevLoading, export: false }));
  };

  useEffect(() => {
    fetchPickupRequests();
  }, [pagination.currentPage, pagination.limit, fetchPickupRequests]);

  return (
    <div>
      <PageHeader
        title="Pickup Request"
        childRoutes={[
          { path: "/pickup-request", breadcrumbName: "Pickup Request" },
        ]}
      />

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <PickupRequestFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <Row
        className="mt-2 mb-2"
        justify={
          permissions?.includes(PERMISSIONS.EXPORT_LIST)
            ? "space-between"
            : "start"
        }
        align="middle"
      >
        {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
          <Button onClick={exportCSV} loading={loading.export}>
            <DownloadOutlined></DownloadOutlined>
            Export
          </Button>
        )}
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>

      <PickupRequestList
        pickupRequest={pickupRequest}
        loading={loading.orders}
      />

      <Col>
        <Pagination
          total={pagination?.totalSize}
          pageSize={pagination?.limit}
          onChange={(currentPage, limit) => {
            setPagination({ ...pagination, limit, currentPage });
          }}
          current={pagination.currentPage}
        />
      </Col>
    </div>
  );
};

export default PickupRequest;
