import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./UserDetails.css";
import "react-vis/dist/style.css";
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Divider,
  Button,
  Input,
  InputNumber,
  Card,
  Modal,
  Form,
  Pagination,
  message,
  Switch,
  Select,
  Typography,
} from "antd";
import {
  UserOutlined,
  PhoneFilled,
  MailFilled,
  IdcardFilled,
  CreditCardFilled,
  PayCircleOutlined,
  BankFilled,
} from "@ant-design/icons";
import axios from "axios";
import AuditLogList from "./AuditLogList";
import UserCodOrder from "./UserCodOrder";
import UserReturnedOrder from "./UserReturnedOrder";
import UserAnalytic from "./UserAnalytic";
import UserOrder from "./UserOrder";
import { formatDateTime } from "../../helpers/date";
import UserTransaction from "./UserTransaction";
import UserAdminAdjustment from "./UserAdminAdjustment";
import UserOnlinePayment from "./UserOnlinePayment";
import { startCase } from "lodash";
import UserBetaFeatures from "./UserBetaFeatures";
import TopupPackageTag from "../shared/TopupPackageTag";
import { ADMIN_ADJUSTMENT_REMARKS } from "../../constants/adminAdjustmentRemarks";
import { usePermissions } from "../App/PermissionContext";
import { PERMISSIONS } from "../../constants/roles";
import moment from "moment";

const { TabPane } = Tabs;

const UserDetails = ({ token }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState([]);
  const [merchantId, setMerchantId] = useState("");
  const [topupData, setTopupData] = useState([]);
  const [credit, setCredit] = useState(false);
  const [charge, setCharge] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [availablePackages, setAvailablePackages] = useState([]);
  const [availableFeePackages, setAvailableFeePackages] = useState([]);
  const [availableCourierPackages, setAvailableCourierPackages] = useState([]);
  const [memberships, setMemberships] = useState(false);
  const [auditLogs, setAuditLogs] = useState({
    pagination: {},
    data: {},
  });
  const [filters, setFilters] = useState({});
  const [assignConfig, setAssignConfig] = useState(false);

  const [merchant, setMerchant] = useState({});
  const [credits, setCredits] = useState(false);

  const [betaFeatures, setBetaFeatures] = useState([]);
  const { permissions } = usePermissions();

  const routes = [
    {
      path: "",
      breadcrumbName: "App",
    },
    {
      path: "/user",
      breadcrumbName: "User",
    },
    {
      path: `${userId}`,
      breadcrumbName: `${userId}`,
    },
  ];

  const diffInDays = merchant?.packageBoughtAt
    ? (merchant?.packageValidDays || 180) -
      moment().diff(moment(merchant.packageBoughtAt.iso), "days")
    : null;
  const diffLockedInDays = merchant?.packageLockedAt 
    ? (merchant?.packageLockedDays || 0) - moment().diff(moment(merchant.packageLockedAt.iso), 'days')
    : null

  useEffect(() => {
    loadUserDetails();
    loadAuditLog();
  }, [token]);

  useEffect(() => {
    if (merchant.objectId) {
      console.log(merchant);
      loadTransactionsData();
    }

    if (user.objectId) {
      loadAuditLog();
    }
  }, [merchant]);

  const loadAuditLog = async () => {
    const params = new URLSearchParams([
      ["page", auditLogs.pagination.currentPage],
    ]);

    console.log(user.objectId);

    const res = await axios.get(`/api/user/${user.objectId}/audit-logs`, {
      params,
    });
    setAuditLogs({
      data: res.data.data,
      pagination: res.data.pagination,
    });
  };

  const loadUserDetails = async (userId) => {
    try {
      const response = await axios.get(`/api/user/${id}`);
      const user = response.data;
      setUser(user);
      setMerchant(user.merchant);
      setCredits(user.merchant.credits);
      setBetaFeatures(user.betaFeatures ? user.betaFeatures : []);
      setUserId(user.objectId);
      setMerchantId(user.merchant.objectId);
      // Set other necessary state fields here...
    } catch (err) {
      console.log(err);
    }
  };

  const loadTransactionsData = async () => {
    // const response = await axios.get("/api/transactions");
    const response = await axios.get(`api/transactions`, {
      params: {
        merchantId: merchant.objectId,
        limit: 1000,
        ...(filters.startDate !== null && {
          startDate: filters.startDate?.startOf("day")?.toISOString(),
        }),
        ...(filters.endDate !== null && {
          endDate: filters.endDate?.endOf("day")?.toISOString(),
        }),
        // ...(filters.search && {payment_data
        //   searchField: filters.searchField,
        //   search: filters.search,
        // }),
      },
    });
    let tableData = response.data.data.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1
    );

    // Filter for Top Up transactions
    let topupTransaction = tableData.filter(
      (transaction) => transaction.method === "Top Up"
    );

    setTopupData(topupTransaction);

    // Optionally, log or return the topup transactions
    console.log(topupTransaction);
    // Map transaction data and set state for relevant data...
  };

  const loadPackagesData = async () => {
    try {
      const res1 = await axios.get(`/api/topup-packages`);
      setAvailablePackages(res1.data.meta?.packages || []);

      const res2 = await axios.get(`/api/fee-packages`);
      setAvailableFeePackages(res2.data.meta?.packages || []);

      const res3 = await axios.get(`/api/courier-packages`);
      setAvailableCourierPackages(res3.data.meta?.packages || []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAuditLogPageChange = (pageNumber) => {
    setAuditLogs((prevAuditLogs) => ({
      ...prevAuditLogs,
      pagination: {
        ...prevAuditLogs.pagination,
        currentPage: pageNumber,
      },
    }));
    loadAuditLog();
  };

  const handleAssignConfig = async () => {
    try {
      await axios.patch(
        `/api/merchant/${merchant.objectId}/service-provider-configs`,
        {
          dhl: {
            api: "https://api.dhlecommerce.dhl.com",
            clientID: "LTY0NzI2OTAwOA==",
            password: "customerpassword@23113120194",
            soldto: "5298362955",
            pickup: "5298362955",
            prefix: "MYJZS",
          },
        }
      );
      message.info("This user has been assigned to the DHL custom configs.");
    } catch (err) {
      message.error("You are not authorized to perform this operation.");
    }
    setAssignConfig(false);
  };

  const handleResetPackage = async () => {
    try {
      await axios.patch(
        `/api/merchants/${merchant.objectId}/reset-package`);
      message.info("This user Topup Package has been reset. Refresh to verify the change.");
    } catch (err) {
      message.error(
        err.response?.data?.message || "Something went wrong."
      );
    }
  }

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">User Details</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "10px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={12}></Col>
        <Col span={12} style={{ textAlign: "right" }}>
          {/* {permissions?.includes(PERMISSIONS.ASSIGN_DHL_CUSTOM_CONFIG) && ( */}
          <Button
            type="primary"
            size="middle"
            style={{ margin: 10 }}
            onClick={() => {
              setAssignConfig(true);
            }}
          >
            DHL Custom Config
          </Button>
          {/* )} */}
          <Modal
            visible={assignConfig}
            title="Dhl Custom Config"
            onOk={async (val) => {
              const credit = parseFloat(val.credit);
              await axios
                .patch(
                  `/api/merchant/${merchant.objectId}/service-provider-configs`,
                  {
                    dhl: {
                      api: "https://api.dhlecommerce.dhl.com",
                      clientID: "LTY0NzI2OTAwOA==",
                      password: "customerpassword@23113120194",
                      soldto: "5298362955",
                      pickup: "5298362955",
                      prefix: "MYJZS",
                    },
                  }
                )
                .then(function (response) {
                  message.info(
                    `This user have been assign to the DHL custom configs.`
                  );
                })
                .catch((err) => {
                  message.error(
                    "You are not authorized to perform this operation."
                  );
                });
              setAssignConfig(false);
            }}
            onCancel={() => {
              setAssignConfig(false);
            }}
          >
            <div>Assign this user to the DHL custom configs ?</div>
          </Modal>
          {permissions.includes(PERMISSIONS.ASSIGN_PACKAGE) && (
            <Button
              type="primary"
              size="middle"
              style={{ margin: 10 }}
              onClick={() => {
                loadPackagesData();
                setShowPackageModal(true);
              }}
            >
              Manage Package
            </Button>
          )}
          <Modal
            title="Package"
            visible={showPackageModal}
            footer={null}
            onCancel={() => {
              setShowPackageModal(false);
            }}
          >
            <p>
              <strong>Current</strong> :{" "}
              {startCase(merchant?.topupPackage || "-")}
            </p>
            <p>
              <strong>Bought At</strong> :{" "}
              {merchant?.packageBoughtAt
                ? formatDateTime(merchant.packageBoughtAt) +
                  " (" +
                  moment(merchant.packageBoughtAt.iso).fromNow() +
                  ")"
                : "-"}
            </p>
            <p>
              <strong>Valid For</strong> : {merchant?.packageValidDays || 180}{" "}
              days
            </p>
            <p className="mb-2">
              <strong>Expires In</strong> :{" "}
              {diffInDays > 0 && diffInDays + " days"}
              {diffInDays < 0 && (
                <Typography.Text type="danger">
                  Expired ({Math.abs(diffInDays)} days ago)
                </Typography.Text>
              )}
            </p>

            <p>
              <strong>Locked At</strong> :{" "}
                { merchant?.packageLockedAt 
                  ?  formatDateTime(merchant.packageLockedAt) + " (" +
                    moment(merchant.packageLockedAt.iso).fromNow() + ")"
                  : "-" 
                }
              </p>
              <p>
                <strong>Locked For</strong> :{" "}
                { merchant?.packageLockedDays || 0 } days
              </p>
              <p className="mb-2">
                <strong>Unlocked In</strong> :{" "}
                { diffLockedInDays > 0 && diffLockedInDays + " days" }
                { diffLockedInDays < 0 && <Typography.Text type="danger">Unlocked ({Math.abs(diffLockedInDays)} days ago)</Typography.Text> }
            </p>

            <Divider>Edit</Divider>
            <Form
              name="changePackageForm"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
                isPackageLocked: merchant?.isPackageLocked || false,
                packageLockedDays: merchant?.packageLockedDays || 0,
                package: merchant?.topupPackage,
                feePackage: merchant?.feePackage,
                courierPackage: merchant?.courierPackage,
                packageValidDays: merchant?.packageValidDays || 180,
              }}
              onFinish={async (val) => {
                await axios
                  .put(`api/merchants/${merchant.objectId}/package`, {
                    package: val.package,
                    feePackage: val.feePackage,
                    courierPackage: val.courierPackage,
                    packageLockedDays: val.packageLockedDays,
                    packageValidDays: val.packageValidDays
                  })
                  .then(function (response) {
                    message.info(
                      `The Package type has been updated. Refresh to verify the change.`
                    );
                  })
                  .catch((err) => {
                    message.error(
                      err.response?.data?.message || "Something went wrong."
                    );
                  });
                setCharge(false);
              }}
              onFinishFailed={() => {}}
            >
              <Form.Item label="Package" name="package">
                <Select placeholder="Choose Package" style={{ width: 200 }}>
                  {availablePackages.map((pkg) => (
                    <Select.Option value={pkg}>{startCase(pkg)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Fee Package" name="feePackage">
                <Select placeholder="Choose Package" style={{ width: 200 }}>
                  {availableFeePackages.map((pkg) => (
                    <Select.Option value={pkg}>{startCase(pkg)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Courier Package" name="courierPackage">
                <Select placeholder="Choose Package" style={{ width: 200 }}>
                  {availableCourierPackages.map((pkg) => (
                    <Select.Option value={pkg}>{startCase(pkg)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Valid For" name="packageValidDays" extra="days">
                <InputNumber min={1} step={1} />
              </Form.Item>
              <Form.Item
                  label="Locked For"
                  name="packageLockedDays"
                  extra="days"
                  tooltip={<p>- To remove lock, set to 0. <br/> - To adjust Lock duration, calculate based on "Locked For" date.</p>}
                >
                  <InputNumber min={0} step={1} />
                </Form.Item>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "6px",
                  height: "100%",
                }}
              >
                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      handleResetPackage()
                    }}
                  >
                    Reset Package Locked Date
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>

          <Modal
            title="Change Membership"
            visible={memberships}
            onOk={() => {
              setMemberships(false);
            }}
            onCancel={() => {
              setMemberships(false);
            }}
          >
            <Form
              name="changeMembershipForm"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={async (val) => {
                await axios
                  .patch("api/merchant/update/member", {
                    merchantId: merchant.objectId,
                    memberType: val.newMembership,
                  })
                  .then(function (response) {
                    message.info(
                      `The Member type has change to ${response.data.merchant.memberType}`
                    );
                  })
                  .catch((err) => {
                    message.error(
                      "You are not authorized to perform this operation."
                    );
                  });
                //console.log(this.state);
                setCharge(false);
              }}
              onFinishFailed={() => {}}
            >
              <p>
                Current Membership : {user.memberType ? user.memberType : "-"}
              </p>
              <Form.Item
                label="Member Type"
                name="newMembership"
                rules={[
                  {
                    required: true,
                    message: "Please choose the memberType",
                  },
                ]}
              >
                <Select
                  placeholder="Choose Membertype"
                  style={{ width: 200 }}
                  allowClear
                  //onChange={value => onFiltersChange({...filters, channel: value})}
                >
                  <Select.Option value="Vip">VIP 10000</Select.Option>
                  <Select.Option value="Vip_5000">VIP 5000</Select.Option>
                  <Select.Option value="Vip_1000">VIP 1000</Select.Option>
                  <Select.Option value="Membership">Merbership</Select.Option>
                  <Select.Option value="Normal">Normal</Select.Option>
                  <Select.Option value="fookai_6_50">Fookai 6.50</Select.Option>
                  <Select.Option value="fookai_special">
                    Fookai Sp.
                  </Select.Option>
                  <Select.Option value="first_5_kg_special">
                    First 5kg Sp.
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {permissions?.includes(PERMISSIONS.EDIT_PHONE_EMAIL) && (
            <Button
              type="primary"
              size="middle"
              style={{ margin: 10 }}
              onClick={() => {
                setEditDetails(true);
              }}
            >
              Edit Details
            </Button>
          )}
          <Modal
            title="Edit Details"
            visible={editDetails}
            footer={null}
            onCancel={() => {
              setEditDetails(false);
            }}
            onOk={() => {
              setEditDetails(false);
            }}
          >
            <p>
              <strong>Phone Number</strong> : {user.countryCode + user.phone}
            </p>
            <p>
              <strong>Email</strong> : {user.email}
            </p>
            <Divider>Edit</Divider>
            <Form
              name="changePackageForm"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                email: user.email,
                phone: user.phone,
                countryCode: user.countryCode,
              }}
              onFinish={async (val) => {
                setEditDetails(false);
                await axios
                  .put(`/api/user/update`, {
                    userId: userId,
                    userData: {
                      email: val.email,
                      phone: val.phone,
                      countryCode: val.countryCode,
                    },
                  })
                  .then(function (response) {
                    message.info(
                      `The user details has been updated. Refresh to verify the change.`
                    );
                  })
                  .catch((err) => {
                    message.error(
                      err.response?.data?.message || "Something went wrong."
                    );
                  });
                setCharge(false);
              }}
              onFinishFailed={() => {}}
            >
              <Form.Item label="Phone" required>
                <Input.Group compact>
                  <Form.Item
                    name="countryCode"
                    noStyle
                    rules={[
                      { required: true, message: "Country code is required" },
                    ]}
                  >
                    <Select style={{ width: "30%" }}>
                      <Select.Option value="+60">+60</Select.Option>
                      <Select.Option value="+65">+65</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    noStyle
                    rules={[
                      { required: true, message: "Phone number is required" },
                      {
                        pattern: /^\d{9,}$/,
                        message:
                          "Phone number must be at least 9 digits and numeric only",
                      },
                    ]}
                  >
                    <Input style={{ width: "70%" }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Email is required" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          {permissions.includes(PERMISSIONS.CHARGE_CARD) && (
            <Button
              type="primary"
              size="middle"
              style={{ margin: 10 }}
              onClick={() => {
                setCharge(true);
              }}
            >
              Charge Card
            </Button>
          )}
          <Modal
            title="Charge Card"
            visible={charge}
            onOk={() => {
              setCharge(false);
            }}
            onCancel={() => {
              setCharge(false);
            }}
          >
            <Form
              name="chargeform"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={async (val) => {
                const credit = parseFloat(val.credit);
                await axios
                  .post("/api/user/charge", {
                    amt: credit,
                    merchant: merchant.objectId,
                    remarks: val.remarks,
                  })
                  .catch((err) => {
                    message.error(
                      "You are not authorized to perform this operation."
                    );
                  });
                //console.log(this.state);
                setCharge(false);
              }}
              onFinishFailed={() => {}}
            >
              <Form.Item
                label="Amount"
                name="credit"
                rules={[
                  {
                    required: true,
                    message: "Please input your amount!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Please input your remarks!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {permissions.includes(PERMISSIONS.ADMIN_MANUAL_TRANSACTION) && (
            <Button
              type="primary"
              size="middle"
              Transaction
              onClick={() => {
                setCredit(true);
              }}
            >
              Manage Credit
            </Button>
          )}
          <Modal
            title="Manage Credit"
            visible={credit}
            onOk={() => {
              setCredit(false);
            }}
            onCancel={() => {
              setCredit(false);
            }}
          >
            <Form
              name="creditform"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={async (val) => {
                const credit = parseFloat(val.credit);
                await axios
                  .post("/api/user/credit", {
                    credit: credit,
                    user_id: userId,
                    remarks: val.remarks,
                    reference: val.reference,
                  })
                  .catch((err) => {
                    message.error(
                      "You are not authorized to perform this operation."
                    );
                  });
                setCredit(false);
              }}
              onFinishFailed={() => {}}
            >
              <Form.Item
                label="Credit"
                name="credit"
                rules={[
                  {
                    required: true,
                    message: "Please input your credit!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Please select your remarks!",
                  },
                ]}
              >
                <Select>
                  {ADMIN_ADJUSTMENT_REMARKS.map((remark) => {
                    return <Select.Option key={remark}>{remark}</Select.Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Reference" name="reference">
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Button
            type="primary"
            size="middle"
            className="back-btn"
            onClick={() => (window.location.href = "/user")}
          >
            Back
          </Button>
        </Col>
      </Row>
      <div className="table">
        <Tabs defaultActiveKey="1" animated={true}>
          <TabPane tab="USER DETAILS" key="1">
            <Row className="mb-2 cod-stats" gutter={16}>
              <Col span={8}>
                <Card style={{ width: 300 }}>
                  <div style={{ textAlign: "center", color: "#575757" }}>
                    <UserOutlined style={{ fontSize: 70 }} />
                    <p className="user-info">
                      {user.alias}
                      <br />
                      <PhoneFilled />
                      &ensp;{user.countryCode}
                      {user.phone}
                      <br />
                      <MailFilled />
                      &ensp;{user.email}
                      <br />
                      <IdcardFilled />
                      &ensp;{startCase(merchant.topupPackage)}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col span={8}>
                <Card style={{ width: 300 }}>
                  <div style={{ textAlign: "center", color: "#575757" }}>
                    <PayCircleOutlined style={{ fontSize: 70 }} />
                    <p className="user-info">
                      <br />
                      <BankFilled />
                      &ensp;{merchant?.bankName} ({merchant?.bankAccount})
                      <br />
                      <CreditCardFilled />
                      &ensp;{merchant?.cardNumber || "-"}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>

            <div style={{ padding: "0 20px" }}>
              <Row gutter={[8, 8]}>
                <Col span={8}>Date Registration Account:</Col>
                <Col span={12}>
                  {user.createdAt ? formatDateTime(user.createdAt) : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Merchant Name :</Col>
                <Col span={12}>{merchant.name ? merchant.name : "-"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Topup Package :</Col>
                <Col span={12}>
                  <TopupPackageTag packageKey={merchant.topupPackage} />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Referral Code :</Col>
                <Col span={12}>
                  {user.referralCode ? user.referralCode : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Business Registration Number :</Col>
                <Col span={12}>{merchant.BRN ? merchant.BRN : "-"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Credits Balance:</Col>
                <Col span={12}>
                  {credits ? "RM" + merchant.credits.toFixed(2) : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Total Topup :</Col>
                <Col span={12}>
                  {merchant.totalTopup ? "RM" + merchant.totalTopup : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Bank Name :</Col>
                <Col span={12}>
                  {merchant.bankName ? merchant.bankName : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Bank Account :</Col>
                <Col span={12}>
                  {merchant.bankAccount ? merchant.bankAccount : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Bank Holder Name :</Col>
                <Col span={12}>
                  {merchant.bankHolderName ? merchant.bankHolderName : "-"}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Credit Card No. :</Col>
                <Col span={12}>{merchant?.cardNumber || "-"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Total Order Parcel :</Col>
                <Col span={12}>{user.total ? user.total : "0"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Total Topup Transaction :</Col>
                <Col span={12}>{topupData ? topupData.length : "-"}</Col>
              </Row>
              {/* <Row gutter={[8, 8]}>
                <Col span={8}>Pending Remittance : </Col>
                <Col span={12}>
                  {payment_data.pendingRemittance
                    ? `RM ${payment_data.pendingRemittance}`
                    : "-"}
                </Col>
              </Row> */}
              {/* <Row gutter={[8, 8]}>
                <Col span={8}>Completed Remittance : </Col>
                <Col span={12}>
                  {payment_data.paymentAdvice
                    ? `RM ${payment_data.paymentAdvice}`
                    : "-"}
                </Col>
              </Row> */}
              <Row gutter={[8, 8]}>
                <Col span={8}>Where you heard abour us : </Col>
                <Col span={12}>{user.heardFrom ? user.heardFrom : "-"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Account Manager : </Col>
                <Col span={12}>{user.manager ? user.manager.email : "-"}</Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>Credit Tagging : </Col>
                <Col span={12}>{user.tagging ? user.tagging : "-"}</Col>
              </Row>
            </div>
          </TabPane>
          {permissions.includes(PERMISSIONS.VIEW_ANALYSIS_PAGE) && (
            <TabPane tab="ANALYTICS" key="analytics">
              {merchantId && <UserAnalytic merchantId={merchantId} />}
            </TabPane>
          )}
          <TabPane tab="TRANSACTIONS" key="all-transactions">
            <Tabs defaultActiveKey="1" type="card" animated={true} size="small">
              <TabPane tab="ALL TRANSACTION" key="10" animated={false}>
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={""}
                  />
                )}
              </TabPane>
              <TabPane tab="CONSIGNMENT PURCHASE" key="2" animated={false}>
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"consignment_purchase"}
                  />
                )}
              </TabPane>
              <TabPane tab="REFUND" key="3">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={[
                      "Refund System Error",
                      "Refund Cancellation Order"
                    ]}
                  />
                )}
              </TabPane>
              <TabPane tab="COD REFUND" key="4">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"refund-cod-charge"}
                  />
                )}
              </TabPane>
              <TabPane tab="NEXT DAY REFUND" key="5">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"refund-next-day-remittance-charge"}
                  />
                )}
              </TabPane>
              <TabPane tab="ONLINE PAYMENT" key="6">
                {merchantId && (
                  <UserOnlinePayment
                    merchantId={merchantId}
                    loading={loading}
                  />
                )}
              </TabPane>
              <TabPane tab="ADMIN ADJUSTMENT" key="7">
                {merchantId && (
                  <UserAdminAdjustment
                    merchantId={merchantId}
                    loading={loading}
                  />
                )}
              </TabPane>
              <TabPane tab="WEIGHT DISPUTE" key="8">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"weight-dispute"}
                  />
                )}
              </TabPane>
              <TabPane tab="WEIGHT DISPUTE REFUND" key="weightDisputeRefund">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"refund-weight-dispute"}
                  />
                )}
              </TabPane>
              <TabPane tab="RETURN HANDLING FEE REFUND" key="returnFeeRefund">
                {merchantId && (
                  <UserTransaction
                    merchantId={merchantId}
                    loading={loading}
                    method={"Refund Return Handling Fee"}
                  />
                )}
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="ALL ORDER" key="order-history">
            {merchantId && (
              <UserOrder merchantId={merchantId} userId={userId} />
            )}
          </TabPane>
          <TabPane tab="COD ORDER" key="7">
            {merchantId && (
              <UserCodOrder merchantId={merchantId} userId={userId} />
            )}
          </TabPane>
          <TabPane tab="RETURNED ORDER" key="returned-order">
            {merchantId && (
              <UserReturnedOrder merchantId={merchantId} userId={userId} />
            )}
          </TabPane>
          <TabPane tab="AUDIT LOG" key="8">
            <Pagination
              style={{ textAlign: "right" }}
              defaultCurrent={1}
              total={auditLogs.pagination.totalSize}
              onChange={(pageNumber) => this.onAuditLogPageChange(pageNumber)}
            />

            <AuditLogList auditLogs={auditLogs.data} />

            <Pagination
              style={{ textAlign: "right" }}
              defaultCurrent={1}
              total={auditLogs.pagination.totalSize}
              onChange={(pageNumber) => this.onAuditLogPageChange(pageNumber)}
            />
          </TabPane>
          <TabPane tab="FEATURES" key="beta-features">
            <UserBetaFeatures userId={userId} betaFeatures={betaFeatures} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default UserDetails;
