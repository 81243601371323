import React from "react";
import axios from "axios";
import { Table, Tooltip, Button, Modal, message } from "antd";

import { formatDateTime } from "../../helpers/date";

import ServiceProviderTag from "../shared/ServiceProviderTag";

const PickupRequestList = ({ pickupRequest, loading }) => {
  const table = [
    {
      title: "Pickup Id",
      dataIndex: "id",
    },
    {
      title: "Collection Reference Id",
      dataIndex: ["pickupInfo", "collectionRefId"],
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Consign No",
      dataIndex: ["orderInfo", "consign_no"],
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Courier",
      dataIndex: ["pickupInfo", "serviceProvider"],
      render: (text) =>
        text ? <ServiceProviderTag serviceProviderKey={text} /> : "",
    },
    {
      title: "Order Status",
      dataIndex: ["orderInfo", "internalStatus"],
      render: (internalStatus, record) => {
        const status = record?.orderInfo?.status; // Access the `status` field for the tooltip
        return (
          <Tooltip title={status}>
            {internalStatus ? <strong>{internalStatus}</strong> : "-"}
          </Tooltip>
        );
      },
    },
    {
      title: "Pickup Name",
      dataIndex: ["pickupAddress", "name"],
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Pickup Email",
      dataIndex: ["pickupAddress", "email"],
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Pickup Phone",
      dataIndex: ["pickupAddress", "phone"],
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Pickup Address",
      dataIndex: ["pickupAddress"],
      render: (address) => {
        if (!address) return "-";

        const {
          line1 = "",
          line2 = "",
          postcode = "",
          state = "",
          country = "",
        } = address;

        const formattedAddress = [line1, line2, postcode, state, country]
          .filter((part) => part)
          .join(", ");

        return formattedAddress || "-";
      },
    },
    {
      title: "Checkout At (YYYY-MM-DD)",
      dataIndex: ["orderInfo", "paymentSuccessAt"],
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Created At (YYYY-MM-DD)",
      dataIndex: "createdAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Action",
      dataIndex: ["pickupInfo", "serviceProvider"],
      align: "center",
      render: (text, record) =>
        text === "aramex" && !record.cancelled ? (
          <Button type="primary" onClick={() => cancelPickup(record)}>
            Cancel
          </Button>
        ) : record.cancelled ? (
          "Cancelled"
        ) : (
          "-"
        ),
    },
  ];

  const cancelPickup = async (pickup) => {
    console.log(pickup);
    try {
      const confirmationMessage = (
        <div>
          <p className="marginBottom-20">
            Pickup Id : <strong>{pickup.id}</strong>
          </p>
        </div>
      );

      Modal.confirm({
        content: confirmationMessage,
        title: `Confirm to cancel the pickup request ?`,
        width: 800,
        async onOk() {
          await axios
            .post("api/riderInfo/cancel", {
              id: pickup.id,
            })
            .then((res) => {
              message.info(
                `Order (${pickup.orderInfo.consign_no}) has been marked as Cancelled By User.`
              );
            })
            .catch((error) => {
              message.error(error.response.data.message || error.message);
            });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="table">
      <Table
        rowKey="objectId"
        className="table-section"
        columns={table}
        dataSource={pickupRequest}
        pagination={false}
        scroll={{ x: true }}
        loading={loading}
      />
    </div>
  );
};

export default PickupRequestList;
