import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Spin,
  message,
  Space,
} from "antd";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import moment from "moment";

import PageHeader from "../Layout/PageHeader";
import AnalysisFilters from "./AnalysisFilters";

ChartJS.register(...registerables);

const endOfThisMonth = moment().subtract(2, "months").endOf("month");
const startOfThisMonth = moment().subtract(2, "months").startOf("month");

const Usage = () => {
  const [filters, setFilters] = useState({
    startDate: startOfThisMonth,
    endDate: endOfThisMonth,
  });

  const [usageStats, setUsageStats] = useState([]);
  const [loading, setLoading] = useState({
    user: false,
    userMonthly: false,
    userChannel: false,
    topup: false,
  });

  useEffect(() => {
    const dateParams = {
      startDate: filters.startDate?.startOf("day")?.toISOString(),
      endDate: filters.endDate?.endOf("day")?.toISOString(),
    };

    const fetchUsageStats = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, user: true }));

      try {
        const res = await axios.get(`/api/analysis/usage`, {
          params: { ...dateParams },
        });
        setUsageStats(res.data.data);
      } catch (error) {
        message.error(error.response?.data?.message || error.message, 0);
      }

      setLoading((prevLoading) => ({ ...prevLoading, user: false }));
    };

    fetchUsageStats();
  }, [filters.startDate, filters.endDate]);

  return (
    <React.Fragment>
      <PageHeader title="Usage Analytics" childRoutes={[]} />

      <Collapse defaultActiveKey="filters">
        <Collapse.Panel
          header={`Filters (${filters.startDate.format(
            "MMM YYYY"
          )} - ${filters.endDate.format("MMM YYYY")})`}
          key="filters"
        >
          <Space>
            <AnalysisFilters
              filters={filters}
              onFiltersChange={(filters) => setFilters(filters)}
              type="day"
            />
          </Space>
        </Collapse.Panel>
      </Collapse>

      <>
        <Typography.Title level={4} className="mt-4">
          Usage
        </Typography.Title>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Card
              title="Last 7 days Total Topup and Weekly Usage"
              style={{ width: "50%" }}
            >
              {loading.user ? (
                <Spin />
              ) : (
                <Chart
                  type="line"
                  data={{
                    labels: usageStats.map((item) => item.dailyTotals),
                    datasets: [
                      {
                        type: "line",
                        label: "Day 1-7",
                        data: usageStats.map((item) => item.rangeWeek1),
                        backgroundColor: "rgba(255, 99, 132, 0.6)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1,
                        zIndex: 10,
                      },
                      {
                        type: "line",
                        label: "Day 8-14",
                        data: usageStats.map((item) => item.rangeWeek2),
                        backgroundColor: "rgba(244, 187, 68, 0.6)",
                        borderColor: "rgba(244, 187, 68, 1)",
                        borderWidth: 1,
                        zIndex: 10,
                      },
                      {
                        type: "line",
                        label: "Day 15-30",
                        data: usageStats.map((item) => item.rangeWeek3),
                        backgroundColor: "rgba(210, 43, 43, 0.6)",
                        borderColor: "rgba(210, 43, 43, 1)",
                        borderWidth: 1,
                        zIndex: 10,
                      },
                      {
                        type: "bar",
                        label: "Total Topup Amount",
                        data: usageStats.map(
                          (item) => item.totalDateRangeAmount
                        ),
                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                        zIndex: 1,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      A: {
                        type: "linear",
                        position: "left",
                        ticks: { color: "rgb(255, 99, 132)" },
                      },
                    },
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
      </>
    </React.Fragment>
  );
};

export default Usage;
