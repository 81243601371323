import React from "react";
import { DatePicker, Space, notification } from "antd";

const AnalysisFilters = ({ filters, onFiltersChange, type }) => {
  return (
    <Space size="large">
      {type === "day" ? (
        <div>
          Date :{` `}
          <DatePicker.RangePicker
            allowClear={false}
            allowEmpty={false}
            value={[filters.startDate, filters.endDate]}
            onChange={(dates) => {
              if (dates && dates[1].diff(dates[0], "days") > 90) {
                notification.error({
                  message: "Date Range cannot be more than 90 days.",
                  placement: "bottomRight",
                });
                return false;
              }

              dates
                ? onFiltersChange({
                    ...filters,
                    startDate: dates[0],
                    endDate: dates[1],
                  })
                : onFiltersChange({
                    ...filters,
                    startDate: undefined,
                    endDate: undefined,
                  });
            }}
          />
        </div>
      ) : (
        <div>
          Month Range :{` `}
          <DatePicker.RangePicker
            allowClear={false}
            allowEmpty={false}
            picker="month"
            value={[filters.startDate, filters.endDate]}
            onChange={(dates) =>
              dates
                ? onFiltersChange({
                    ...filters,
                    startDate: dates[0],
                    endDate: dates[1],
                  })
                : onFiltersChange({
                    ...filters,
                    startDate: undefined,
                    endDate: undefined,
                  })
            }
          />
        </div>
      )}
    </Space>
  );
};

export default AnalysisFilters;
