import React, { useState, useEffect } from 'react'
import { Table, Pagination, Row, Col, Tooltip, Popover } from "antd"
import axios from 'axios'

import { formatDateTime } from '../../helpers/date.js'
import ServiceProviderTag from '../shared/ServiceProviderTag.js'
import TransactionButtons from "./TransactionButtons.js";
import { InfoCircleOutlined } from "@ant-design/icons";


const TransactionList = ({ globalFilters, types, permission }) => {
  const [transactions, setTransactions] = useState()
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 0 })
  const [filters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true)
      const res = await axios.get(`api/transactions`, {
        params: {
          types: types,
          limit: pagination.limit,
          page: pagination.currentPage,
          sort: filters.sort,
          order: filters.order,
          status: filters.status,
          ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
          ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
          ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
          ...(globalFilters.tagging && { tagging: globalFilters.tagging }),
        }
      })
      setTransactions(res.data.data)
      setPagination(res.data.pagination)
      setIsLoading(false)
    }

    fetchTransactions()
  }, [pagination.currentPage, pagination.limit, filters, globalFilters])

  const columns = types !== 'consignment_purchase' ? [
    {
      title: "Transaction ID",
      dataIndex: "objectId",
    },
    {
      title: "User Email",
      dataIndex: "merchant",
      key: "username",
      render: text => <Tooltip title={ text?.user?.username || "-" }>{ text?.user?.email || "-"} </Tooltip>
    },
    {
      title: "Order ID",
      dataIndex: "order",
      key: "orderId",
      render: (text, row) => text?.objectId || row.ref_no || "-"
    },
    {
      title: "Tracking No",
      dataIndex: "order",
      key: "trackingNo",
      render: text => text?.consign_no || "-"
    },
    {
      title: "Courier",
      dataIndex: "order",
      render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => text ? text.toFixed(2) : "-"
    },
    {
      title: "Method",
      dataIndex: "method"
    },
    {
      title: "Remarks",
      dataIndex: "remarks"
    },
    {
      title: "Charged At (YYYY-MM-DD)",
      dataIndex: "creditSuccessAt",
      render: text => text ? formatDateTime(text) : "Failed"
    },
    {
      title: "Order Created At (YYYY-MM-DD)",
      dataIndex: "order",
      key: "createdAt",
      render: text => text?.createdAt ? formatDateTime(text.createdAt) : "-"
    },
    {
      title: "User Tagging",
      dataIndex: "merchant",
      key: "tagging",
      render: (text) => text?.user?.tagging || "-",
    },
  ] : [ // columns for consignment purchase
    {
      title: "Transaction ID",
      dataIndex: "objectId",
    },
    {
      title: "User Email",
      dataIndex: "merchant",
      key: "username",
      render: text => <Tooltip title={ text?.user?.username || "-" }>{ text?.user?.email || "-"} </Tooltip>
    },
    {
      title: "Order ID",
      dataIndex: "order",
      key: "orderId",
      render: (text, row) => text?.objectId || row.ref_no || "-"
    },
    {
      title: "Tracking No",
      dataIndex: "order",
      key: "trackingNo",
      render: text => text?.consign_no || "-"
    },
    {
      title: "Courier",
      dataIndex: "order",
      render: (text, row) => text?.serviceProvider ? <ServiceProviderTag serviceProviderKey={text.serviceProvider} /> : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => text ? text.toFixed(2) : "-"
    },
    {
      title: (
        <span>
          Original Price{" "}
          <Tooltip title="Original Price recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","snapshot"],
      render: (snapshot) => snapshot?.price || "-",
    },
    {
      title: (
        <span>
          Prev Price{" "}
          <Tooltip title="Price before the last Weight Dispute update.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","original"],
      render: (original) => original?.price || "-",
    },
    {
      title: (
        <span>
          Actual Price{" "}
          <Tooltip title="Latest Price.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","price"],
    },
    {
      title: (
        <span>
          Original COD{" "}
          <Tooltip title="Original COD amount recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","snapshot"],
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.cod
          ? `${snapshot?.codCurrency?.currency || "RM"} ${snapshot?.cod.toFixed(
              2
            )}`
          : "-";
      },
    },
    {
      title: "COD",
      dataIndex: ["order","cod"],
      ellipsis: true,
      render: (cod, order) => {
        if (cod && order.codCurrency) {
          const { currency, fromChargeRate, fromRemitRate } = order.codCurrency;
          const rateInfo = (
            <div>
              {fromChargeRate && (
                <p>
                  <strong>Charge Rate</strong>: {currency} 1.00 to RM{" "}
                  {fromChargeRate}
                </p>
              )}
              {fromRemitRate && (
                <p>
                  <strong>Remit Rate</strong>: {currency} 1.00 to RM{" "}
                  {fromRemitRate}
                </p>
              )}
            </div>
          );

          return (
            <div>
              <span>{`${currency} ${cod.toFixed(2)}`}</span>
              {` `}
              <Popover content={rateInfo} title="Conversion Rates">
                <InfoCircleOutlined />
              </Popover>
            </div>
          );
        }

        return cod ? `RM ${cod.toFixed(2)}` : "-";
      },
    },
    {
      title: "Insurance",
      dataIndex: ["order", "insurance"], 
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Postage Price",
      dataIndex: ["order", "prices", "courier", "postage"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "SMS Charge",
      dataIndex: ["order", "prices", "courier", "pickupSMS"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Email Charge",
      dataIndex: ["order", "prices", "courier", "pickupEmail"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Whatsapp Charge",
      dataIndex: ["order", "prices", "courier", "pickupWhatsapp"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Reschedule Whatsapp Charge",
      dataIndex: ["order", "prices", "courier", "rescheduleWhatsapp"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Cod Amount Charge",
      dataIndex: ["order", "prices", "charges", "hideCodAmount"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Logo Charge",
      dataIndex: ["order", "prices", "charges", "hideLogo"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Address Charge",
      dataIndex: ["order", "prices", "charges", "hideAddress"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Insurance Charge",
      dataIndex: ["order", "prices", "courier", "insurance"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original COD Charge{" "}
          <Tooltip title="Original COD Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","snapshot"],
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.prices?.courier?.cod
          ? Number(snapshot?.prices?.courier?.cod).toFixed(2)
          : "-";
      },
    },
    {
      title: "COD Charge",
      dataIndex: ["order", "prices", "courier", "cod"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original NDR Charge{" "}
          <Tooltip title="Original Next-Day Remittance Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","snapshot"],
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.prices?.charges?.nextDayRemittance
          ? Number(snapshot?.prices?.charges?.nextDayRemittance).toFixed(2)
          : "-";
      },
    },
    {
      title: "NDR Charge",
      dataIndex: ["order", "prices", "charges", "nextDayRemittance"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original Return Handling Fee Charge{" "}
          <Tooltip title=" Original Return Handling Fee Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: ["order","snapshot"],
      ellipsis: true,
      render: snapshot => {
        return snapshot?.prices?.courier?.returnFee ? Number(snapshot?.prices?.courier?.returnFee).toFixed(2) : "-"
      },
    },
    {
      title: "Return Handling Fee Charge",
      dataIndex: ["order", "prices", "courier", "returnFee"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Method",
      dataIndex: "method"
    },
    {
      title: "Remarks",
      dataIndex: "remarks"
    },
    {
      title: "Charged At (YYYY-MM-DD)",
      dataIndex: "creditSuccessAt",
      render: text => text ? formatDateTime(text) : "Failed"
    },
    {
      title: "Order Created At (YYYY-MM-DD)",
      dataIndex: "order",
      key: "createdAt",
      render: text => text?.createdAt ? formatDateTime(text.createdAt) : "-"
    },
    {
      title: "User Tagging",
      dataIndex: "merchant",
      key: "tagging",
      render: (text) => text?.user?.tagging || "-",
    },
    {
      title: "User Tagging 2",
      dataIndex: "merchant",
      key: "tagging",
      render: (text) => text?.user?.tagging || "-",
    },
  ];

  return (
    <React.Fragment>
     <Row className="mt-2 mb-2" justify="space-between">
            <TransactionButtons
            
            filters={{
              types : types,
              ...(globalFilters.startDate !== null) && { startDate: globalFilters.startDate?.startOf('day')?.toISOString() },
              ...(globalFilters.endDate !== null) && { endDate: globalFilters.endDate?.endOf('day')?.toISOString() },
              ...(globalFilters.search && { searchField: globalFilters.searchField, search: globalFilters.search }),
              ...(globalFilters.tagging && { tagging: globalFilters.tagging }),
              pagination:pagination
            }}/>
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={transactions}
        pagination={false}
        scroll={{ x: true }}
        loading={isLoading}
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TransactionList