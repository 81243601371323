import React, { useState } from "react";
import { DatePicker, Space, Select, Input } from "antd";

const PickupRequestFilters = ({ filters, onFiltersChange }) => {
  const [searchField, setSearchField] = useState("trackingNo");

  const searchBy = (
    <Select
      defaultValue="trackingNo"
      className="select-before"
      onChange={(value) => setSearchField(value)}
    >
      <Select.Option value="trackingNo">Tracking No</Select.Option>

      <Select.Option value="orderId">Order Id</Select.Option>
      <Select.Option value="pickupEmail">Pickup Email</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      <div>
        Order At :{` `}
        <DatePicker.RangePicker
          allowClear={false}
          allowEmpty={false}
          value={[filters.startDate, filters.endDate]}
          onChange={(dates) => {
            dates
              ? onFiltersChange({
                  ...filters,
                  startDate: dates[0],
                  endDate: dates[1],
                })
              : onFiltersChange({
                  ...filters,
                  startDate: undefined,
                  endDate: undefined,
                });
          }}
        />
      </div>

      <div>
        Search :{` `}
        <Input.Search
          addonBefore={searchBy}
          placeholder="Search"
          onSearch={(term) =>
            onFiltersChange({ ...filters, search: term, searchField })
          }
        />
      </div>

      <div>
        Courier :{` `}
        <Select
          placeholder="ALL"
          style={{ width: 200 }}
          allowClear
          onChange={(value) =>
            onFiltersChange({ ...filters, serviceProvider: value })
          }
        >
          <Select.Option value="dhl">DHL</Select.Option>
          <Select.Option value="flash">Flash</Select.Option>
          <Select.Option value="jnt">JnT</Select.Option>
          <Select.Option value="ninjavan">Ninjavan</Select.Option>
          <Select.Option value="pickupp">Pickupp</Select.Option>
          <Select.Option value="teleport">Teleport</Select.Option>
          <Select.Option value="sfstandard">SF Standard</Select.Option>
          <Select.Option value="sfeconomy">SF Economy</Select.Option>
          <Select.Option value="fedex">Fedex</Select.Option>
          <Select.Option value="aramex">Aramex</Select.Option>
          <Select.Option value="poslaju">Poslaju</Select.Option>
          <Select.Option value="citylink">Citylink</Select.Option>
          <Select.Option value="lineclear">Lineclear</Select.Option>
          <Select.Option value="best">Best</Select.Option>
          <Select.Option value="imile">IMile</Select.Option>
          <Select.Option value="redly">Redly</Select.Option>
        </Select>
      </div>
    </Space>
  );
};

export default PickupRequestFilters;
